// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    // 開発環境のDB
    //apiKey: "AIzaSyB5ElaTPt-S8cbvfBKlex7C0JykDiR8yTA",
    //authDomain: "rojitest-168ef.firebaseapp.com",
    //projectId: "rojitest-168ef",
    //storageBucket: "rojitest-168ef.appspot.com",
    //messagingSenderId: "430452853367",
    //appId: "1:430452853367:web:654e794a0e7e561831d472",
    //measurementId: "G-5SJ626HTVL"

    // 開発&試験環境のDB(手違いで、お試し環境のDBになってしまった)
    apiKey: "AIzaSyBN7iYsGbwzcJ8KjULiU1N1AQYegY7myTU",
    authDomain: "rojichabako.firebaseapp.com",
    databaseURL: "https://rojichabako.firebaseio.com",
    projectId: "rojichabako",
    storageBucket: "rojichabako.appspot.com",
    messagingSenderId: "145336194594",
    appId: "1:145336194594:web:deb6fa199ef0d5d7c69c7b",
    measurementId: "G-Q0EPF7DRPG"

    // 本番環境のDB
    // apiKey: "AIzaSyAQE15DSYC5DSN-2tn67_rvLZqLLRnPnVs",
    // authDomain: "rojichabakopub.firebaseapp.com",
    // databaseURL: "https://rojichabakopub.firebaseio.com",
    // projectId: "rojichabakopub",
    // storageBucket: "rojichabakopub.appspot.com",
    // messagingSenderId: "871998260988",
    // appId: "1:871998260988:web:ca6b4d4edd1e322136f979",
    // measurementId: "G-LL0K3C9P5E"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
